import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Typefaces from "../components/typefaces"

import { Row, Col, Image } from "react-bootstrap"

//images imports
import SocialeSpel1 from "./../images/het sociale spel/fp-het sociale spel.jpg"
import SocialeSpel2 from "./../images/het sociale spel/het sociale spel1.jpg"
import SocialeSpel3 from "./../images/het sociale spel/het sociale spel2.jpg"
import SocialeSpel5 from "./../images/het sociale spel/het sociale spel4.jpg"
import SocialeSpel6 from "./../images/het sociale spel/het sociale spel5.jpg"
import SocialeSpel7 from "./../images/het sociale spel/het sociale spel6.jpg"
import SocialeSpel8 from "./../images/het sociale spel/het sociale spel7.jpg"
import SocialeSpel10 from "./../images/het sociale spel/het sociale spel9.jpg"

const HetSocialeSpel = ({location, id}) => (
    <Layout>
        <Seo title="Het Sociale Spel" description="Het Sociale Spel, by Manon Lambeens" />
        <Row className="">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
              <h1 className="text-uppercase fs-4 text-dark">het sociale spel</h1>
                <p className="textAbout">
                Book design for my own thesis. The book is a workbook/playbook. The cover of the book is a stencil. The stencil has the same shapes I used in the workshop that I gave as a part of my research. There are instructions in the book on how to make your own line drawings. The book is also a documentation of my research. It has pictures of projects such as Tunneltaal, DRUKDRUKDRUK, Folding lines etc.  
                </p>
                <Typefaces types={['Eliza Mono', 'Borgfeld']} />

        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SocialeSpel1} fluid style={{width: '100%'}} alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SocialeSpel2} fluid style={{width: '100%'}} alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={SocialeSpel3} fluid alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={SocialeSpel10} fluid alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={SocialeSpel5} fluid alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={SocialeSpel6} fluid alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SocialeSpel7} fluid style={{width: '100%'}} alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={SocialeSpel8} fluid style={{width: '100%'}} alt="Het Sociale Spel, Manon Lambeens"/>
        </Col>
      </Row>
    </Layout>
)

export default HetSocialeSpel;