import * as React from "react"
import "./../components/layout.css"

const typefaces = ({types}) => (
    <p>
    <h4>
    TYPEFACES
    </h4>
    <ul  className="aboutLists">
        {types.map((type, index)=>{
            return <li key={index}>{type}</li>
        })}
    </ul>
  </p>
)

export default typefaces;